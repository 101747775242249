import axios from 'axios'
import API from '@/API.js'
import { reject } from 'q';

const apiClient = axios.create({
  baseURL: `${API}/cpes/marcas`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('argusblack.token')
  }
})

// Actualización del token de autorización
document.addEventListener('actualizar:token', function(e) {
  apiClient.defaults.headers['Authorization'] = e.detail
})

// Eliminación del token de autorización
document.addEventListener('eliminar:token', function() {
  apiClient.defaults.headers['Authorization'] = null
})

export default {
  actualizar(marca) {
    return new Promise((resolve, reject) => {
      var data = new FormData()

      // Carga del FormData
      Object.keys(marca).map(function(key) {
        data.append(key, marca[key])
      })

      var imagen = document.getElementsByName("foto")

      if (imagen.length > 0) data.append('foto', imagen[0].files[0])

      if (marca._eliminarFotoAntigua) data.append('eliminar_foto_antigua', true)

      var req = new XMLHttpRequest()
      req.open(
        'POST',
        API +
          '/cpes/marcas/' +
          marca.id +
          '?_tk=' +
          localStorage.getItem('argusblack.token'),
        true
      )

      req.onreadystatechange = function() {
        if (req.readyState == 4) {
          if (req.status == 200) {
            resolve({
              data: req.responseText
            })
          }
          else {
            reject({
              response: {
                status: req.status,
                data: req.responseText
              }
            })
          }
        }
      }

      req.send(data)
    })
  },

  eliminar(idMarca) {
    return apiClient.delete(`${idMarca}`)
  },

  guardar(marca) {
    return new Promise((resolve, reject) => {
      var data = new FormData()

      // Carga del FormData
      Object.keys(marca).map(function(key) {
        data.append(key, marca[key])
      })

      var imagen = document.getElementsByName("foto")

      if (imagen.length > 0) data.append('foto', imagen[0].files[0])

      var req = new XMLHttpRequest()
      req.open(
        'POST',
        API + '/cpes/marcas?_tk=' + localStorage.getItem('argusblack.token'),
        true
      )

      req.onreadystatechange = function() {
        if (req.readyState == 4) {
          if (req.status == 200) {
            resolve({
              data: req.responseText
            })
          } else {
            reject({
              status: req.status,
              response: {
                data: req.responseText
              }
            })
          }
        }
      }

      req.send(data)
    })
  },

  marcaJSON(idMarca) {
    return apiClient.get(`${idMarca}.json`)
  },

  marcasJSON(params) {
    params = params || {}
    return apiClient.get(`${API}/cpes/marcas.json`, { params: params })
  }
}
